:root{
    scroll-behavior: inherit;
}
.font-14 ul{
    list-style: inside ;
}
.font_main_p{
    font-size:16px;
}
.list-group-item b{
    color:#495057;
}
.font-14{
    font-size: 14px;;
}
.p-100{
    padding:110px;
    text-align: justify;
}
.Bot_bold{
    font-weight: 600;
    font-size: 16px;
}
@media screen and (max-width:768px){
    .p-100{
        padding:100px 50px;
        text-align: justify;
    }
}
@media screen and (max-width:425px){
    .p-100{
        padding:100px 10px 10px 10px;
        text-align: justify;
    }
}
