.pt-100{
    padding-top:100px;
}
.background_img{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url('../img/banner5.jpg');
    background-size: cover;
    background-position: center;
}
.text_core{
    text-align: justify;
}
.pad_cust_lr{
    padding: 0 150px;
    display: flex;
    flex-wrap: wrap;
    margin: 25px;
}
.card_body_cust{
    /* min-height: 150px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bod_custom{
    border-bottom: 3px solid #2682c0ba;
    border-left: 3px solid #2682c0ba;
}
.translate_cls{
    translate: unset;
    transition: transform 0.7s ease-in-out;
    height:100%;
}


.translate_cls:hover{
    transform: translate(-10px, -10px);
}
.body {
	background: linear-gradient(-45deg, #9E9E9E, #5396c34a, #f5f8fa, #2682c0ba);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	
}
.card_body_cust img{
    width:90px;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.fs_4{
    font-size: 1.5rem;;
}
@media screen and (max-width:1024px) {
    .pad_cust_lr{
        padding: 0 0;
    }
    .fs_4{
        font-size: 18px;
    }
}
@media screen and (max-width:768px) {
    .pad_cust_lr{
        padding: 0 0;
    }
    .fs_4{
        font-size: 15px;
    }
    .fs_14{
        font-size: 14px;
    }
    .card_body_cust img{
        width:70px;
    }
}
.p_mob{
    padding:1.5rem;
}
@media screen and (max-width:425px) {
    .pt-100{
        padding-top:90px;
    }
    .image_drop{
        top: -3px;
    }
    .pad_cust_lr{
        padding: 0 0;
    }
    .p_mob{
        padding:0;
    }
}
