a{
    text-decoration: none;
}
nav{
    border-bottom: 4px solid #2682c0;
}
.nav-link img{
    width:30px;
    height:16px;
}
.cust_cl-auto{
    margin: auto;
}
.border-none, .border-none:focus{
    border:none !important;
    outline: none !important;
    box-shadow: none !important;
}
@media screen and (max-width:425px) {
    .navbar-brand img{
        max-width: 200px;
    }

}
@media screen and (max-width:1024px) {
    .nav-link img{
        width:20px;
        height:12px;
    }
    .navbar-brand img{
        max-width: 200px;
    }
    .nav-link span{
        font-size: 14px !important;
    }
}