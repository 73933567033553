.carousel-item img{
    object-fit: cover;
    height: 500px;
}
.carousel-item::after{
    content:'';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4),rgb(38 130 192 / 61%));
}

#carouselExampleAutoplaying .carousal-item h1 {
    font-size: 72px;
}

.carousel-caption {
    z-index: 9;
    bottom: 10.15rem;
}
.carousel-caption h1{
    font-size: 52px;
    font-weight: 700;
}
.carousel-caption p{
    font-size: 32px;
}

@media screen and (max-width:768px) {
    .carousel-caption h1{
        font-size: 48px;
    }
    .carousel-caption p{
        font-size: 24px;
    }
}
@media screen and (max-width:425px) {
    .carousel-caption h1{
        font-size: 24px;
    }
    .carousel-caption p{
        font-size: 16px;
    }
}