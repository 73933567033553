*{
    margin:0;
    padding:0;
}
body{
    text-decoration: none !important;
}
.clr_white{
    color:#fff !important;
}
p{
    margin-bottom:0 !important;
}
.footer_logo img{
    width:150px;
    height:64px;
}
.footer_icon{
    font-size:14px;
}
.bottom_listing{
    padding-left:0px;
}